<template>
	<div class="w-full">
		<div class="p-4 rounded text-sm" :class="cssClasses" role="alert">
			<p v-if="message" v-html="message" />
			<slot />
			<span v-if="canClose" class="cursor-pointer" @click="$emit('close')">
				<XMarkIcon class="w-5 h-5" />
			</span>
		</div>
	</div>
</template>
<script setup lang="ts">
import {
	XMarkIcon
} from '@heroicons/vue/24/outline';
import {computed, PropType, defineEmits} from "vue";

const props = defineProps({
	type: {
		type: String as PropType<'success' | 'error' | 'warning' | 'info' | 'highlight'>,
		required: false,
		default: 'info'
	},
	message: {
		type: String,
		required: false,
		default: undefined
	},
	canClose: {
		type: Boolean,
		required: false,
		default: false
	},
	textCenter: {
		type: Boolean,
		required: false,
		default: true
	}
});
defineEmits(['close']);

const alertType = computed(() => {
	switch (props.type) {
		case 'success':
			return 'bg-jmi-green3 text-jmi-purple1';
		case 'error':
			return 'bg-jmi-coral5 text-jmi-coral1';
		case 'warning':
			return 'bg-jmi-yellow3 text-jmi-yellow1';
		case 'highlight':
			return 'bg-jmi-bright-purple2 text-white';
		default:
			return 'bg-jmi-purple4 text-jmi-purple1';
	}
});
const canClose = computed(() => {
	return props.canClose ? 'flex items-center justify-between' : '';
});

const cssClasses = computed(() => {
	const textCenter = props.textCenter ? 'text-left' : '';
	return `${alertType.value} ${canClose.value} ${textCenter}`;
});

</script>
